import styled from "styled-components";

const CardUl = styled.ul<{ noPadding?: boolean; columnGap?: boolean }>`
  box-sizing: border-box;
  padding: ${(props) => (props.noPadding ? 0 : "16px 16px 0 16px")};
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: ${(props) =>
    props.columnGap ? "flex-start" : "space-between"};
  column-gap: ${(props) => (props.columnGap ? "14px" : null)};
  margin: 0;
`;

export default CardUl;
